import {Controller} from "@hotwired/stimulus"
import Cookies from "js-cookie"
import {isbot} from "isbot";

const cookieName = "app-consent"

// Connects to data-controller="consent"
export default class extends Controller {
  connect() {
    // Don't show popup for crawlers
    if (isbot(navigator.userAgent)) {
      return;
    }

    if (!Cookies.get(cookieName)) {
      this.element.classList.remove("hidden")
    }
  }

  accept() {
    Cookies.set(cookieName, "true", {expires: 365})
    this.element.classList.add("hidden")
  }
}
