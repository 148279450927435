import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["hamburger", "dropdown", "cross"]

  connect() {
  }

  toggleMenu() {
    console.log("Hamburger clicked")
    this.dropdownTarget.classList.toggle("hidden")
  }

  hamburgerTargetConnected(item) {
    item.addEventListener("click", () => {
      this.toggleMenu()
    })
  }

  crossTargetConnected(item) {
    item.addEventListener("click", () => {
      this.toggleMenu()
    })
  }

  dropdownTargetConnected(item) {
    item.classList.add("hidden")
  }
}
