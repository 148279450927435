import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    console.log("videos", this.element)
  }

  hoverVideo(ev) {
    const el = ev.target;
    let previewUrl = el.dataset.previewUrl;
    if (!previewUrl) {
      previewUrl = el.dataset.thumbnailUrl.replace('thumb', 'preview')
    }
    ev.target.style.backgroundImage = `url(${previewUrl})`;
  }

  unhoverVideo(ev) {
    const el = ev.target;
    const url = el.dataset.thumbnailUrl;
    ev.target.style.backgroundImage = `url(${url})`;
  }
}

